import React from 'react'

import { ExclamationCircleFilled } from '@ant-design/icons'
import styled from '@emotion/styled'
import theme from '@src/styles/theme'
import { localStorageProvider } from '@src/utils/storageProvider'
import { Typography } from 'antd'
import moment from 'moment-timezone'
import { Trans, useTranslation } from 'react-i18next'

import { useSessionStorageItem } from '../../hooks/useSessionStorageItem'
import { Grid, Flex, Box } from '../EmotionLayout'

const BannerText = styled(Typography.Text)`
  color: #ffffff;
  font-weight: 500;
`

export const DismissibleBanner: React.FC = () => {
  const { t } = useTranslation()
  const [dismissed, setDismissed] = useSessionStorageItem(
    'dismissedMaintenanceBanner'
  )
  const [
    dismissedMaintenanceBanner,
    setDismissedMaintenanceBanner
  ] = React.useState(dismissed ?? false)

  const hideBanner = () => {
    setDismissed(true)
    setDismissedMaintenanceBanner(true)
  }
  const language = localStorageProvider.getItem('i18nextLng')

  if (dismissedMaintenanceBanner) return null

  const startTime = moment.utc('2024-10-03 01:00:00')
  const endTime = moment.utc('2024-10-03 02:00:00')

  const timeZoneOffset = moment().utcOffset()
  const timeZone = moment.tz(moment.tz.guess()).format('z')

  const outputStartTime = startTime
    .utcOffset(timeZoneOffset)
    .format('MMMM DD, YYYY h:mm A')
  const outputEndTime = endTime
    .utcOffset(timeZoneOffset)
    .format('MMMM DD, YYYY h:mm A')

  return (
    <Grid
      gridTemplateColumns="1fr 20px"
      minHeight="53"
      width="100%"
      bg={theme.linkBlue}
      boxShadow="0px 1px 2px rgba(0, 0, 0, 0.5)"
      p="large"
    >
      <Flex justifyContent="center" alignItems="center">
        <ExclamationCircleFilled
          style={{ fontSize: '1.3rem', color: '#FFFFFF' }}
        />
        <Box mx="large" textAlign="center">
          <BannerText>
            <Trans
              t={t}
              values={{
                outputStartTime,
                outputEndTime,
                timeZone
              }}
              i18nKey="The Firework CMS will be temporarily down for maintenance on {{outputStartTime}} {{timeZone}} - {{outputEndTime}} {{timeZone}}. Please note the maintenance window may be quicker than expected and functionality may be restored sooner. If you have any questions, please reach out to <11>slasupport@fireworkhq.com</11>"
            >
              The Firework CMS will be temporarily down for maintenance on{' '}
              {{ outputStartTime }} {{ timeZone }} - {{ outputEndTime }}{' '}
              {{ timeZone }}. Please note the maintenance window may be quicker
              than expected and functionality may be restored sooner. If you
              have any questions, please reach out to{' '}
              {language === 'ja' ? (
                <a
                  href="mailTo:japan_support@fireworkhq.com"
                  style={{ color: '#FFFFFF', textDecoration: 'underline' }}
                >
                  japan_support@fireworkhq.com
                </a>
              ) : (
                <a
                  href="mailTo:slasupport@fireworkhq.com"
                  style={{ color: '#FFFFFF', textDecoration: 'underline' }}
                >
                  slasupport@fireworkhq.com
                </a>
              )}
            </Trans>
          </BannerText>
        </Box>
      </Flex>
      <Flex
        justifyContent="flex-end"
        alignItems="center"
        onClick={hideBanner}
        cursor="pointer"
      >
        <Box as="img" src="/exit_white.svg" />
      </Flex>
    </Grid>
  )
}
