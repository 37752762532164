import React, {
  useEffect,
  useState,
  useContext,
  useMemo,
  useCallback
} from 'react'

import loadable from '@loadable/component'
import { FWButton } from '@src/components'
import { DevTools } from '@src/components/DevTools'
import { Flex } from '@src/components/EmotionLayout'
import { useShopifyFullPage } from '@src/hooks/useShopifyFullPage'
import { resetError } from '@src/redux/errors'
import { setIsSidebarCollapsed } from '@src/redux/ui'
import { makeGetRequest } from '@src/selectors/request'
import { message, Result, notification } from 'antd'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useSpring, animated } from 'react-spring'

import { DismissibleBanner } from '../components/DismissibleBanner/DismissibleBanner'
import LoadingIndicator from '../components/LoadingIndicator'
import { BusinessContext } from '../contexts/BusinessContext'
// import { useWindowSize } from '../hooks'
import { useWindowSize, useAdBlockPrompt } from '../hooks'

const Sidebar = loadable(() => import('../layouts/Sidebar'))
const SidebarMask = loadable(() => import('../layouts/SidebarMask'))
const PageContainer = loadable(() => import('../layouts/PageContainer'))
const Navbar = loadable(() => import('../layouts/Navbar'))
const ReviewModal = loadable(() => import('../components/ReviewModal'))
const AccountUsage = loadable(
  () => import('@src/layouts/Sidebar/AccountUsage'),
  {
    resolveComponent: (components) => components.AccountUsage
  }
)
const ErrorNotification = loadable(() =>
  import('@src/components/ErrorNotification')
)

const ChangelogModal = loadable(
  () => import('@src/components/ChangelogModal'),
  {
    resolveComponent: (components) => components.ChangelogModal
  }
)

const sidebarWidth = 230
const collapsedSidebarWidth = 50
// const collapseBreakpoint = 1200
const mobileSidebarBreakpoint = 768
const sidebarAnimationConfig = { tension: 150, friction: 20, clamp: true }

message.config({
  duration: 3
  /**
   * We should not set the global value to 1,
   * sometimes we need to pop up multiple messages, for example: the feature of bulk upload.
   * If you want your message to pop up only one at a time, please use the "key":
   *   message.success({ content: 'only one', key: 'your_key', duration: 2 })
   */
  // maxCount: 1
})
export default function AppLayout({ children }) {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const [mobileSidebarEnabled, setMobileSidebarEnabled] = useState(
    window.innerWidth < mobileSidebarBreakpoint
  )
  // const [mobileSidebarEnabled] = useState(false)
  const [sidebarCollapsed, setSidebarCollapsed] = useState(true)
  const [businessesDidLoad, setBusinessesDidLoad] = useState(false)

  const [showUsage, setShowUsage] = useState(false)
  const [showReviewModal, setShowReviewModal] = useState(false)

  const isFullPage = useShopifyFullPage()

  const businessId = useContext(BusinessContext)
  const errors = useSelector((state) => state.errors)

  // Display error notification with a centralized component, and clear error in redux after display

  useEffect(() => {
    if (errors.message || errors.apiErrorMessage) {
      message.error({
        content: (
          <ErrorNotification
            status={errors.status}
            message={errors.message || errors.apiErrorMessage}
          />
        ),
        className: 'fw-notification',
        icon: <></>,
        onClose: () => {
          dispatch(resetError())
        },
        maxCount: 1
      })
    }
  }, [dispatch, errors])

  useWindowSize((windowSize) => {
    if (
      windowSize.innerWidth < mobileSidebarBreakpoint &&
      !mobileSidebarEnabled
    ) {
      setMobileSidebarEnabled(true)
    } else if (
      windowSize.innerWidth >= mobileSidebarBreakpoint &&
      mobileSidebarEnabled
    ) {
      setMobileSidebarEnabled(false)
    }

    // if (windowSize.innerWidth < collapseBreakpoint && !sidebarCollapsed) {
    //   setSidebarCollapsed(true)
    // } else if (
    //   windowSize.innerWidth >= collapseBreakpoint &&
    //   sidebarCollapsed
    // ) {
    //   setSidebarCollapsed(false)
    // }
  })

  const showAdBlockNotification = useAdBlockPrompt()
  const showAdBlockPopup = () => {
    const e = (window.screen.width - 480) / 2,
      a = (window.screen.height - 400) / 2
    window.open(
      'https://fw-tv.github.io/allowlist/',
      'targetWindow',
      'toolbar=no,\n      location=no,\n      status=no,\n      menubar=no,\n      scrollbars=no,\n      resizable=no,\n      width='
        .concat(480, ',\n      height=')
        .concat(400, ',\n      left=')
        .concat(e, ',\n      top=')
        .concat(a)
    )
  }
  const openAdBlockNotification = useCallback(() => {
    const whiteListBtn = (
      <FWButton type="primary" onClick={() => showAdBlockPopup()}>
        {t('Add to Whitelist')}
      </FWButton>
    )
    const args = {
      message: t('Ad blocker detected'),
      description: t(
        'You might have an ad blocker enabled on your browser which prevents us from showing you the video content on the site. Add us to your Whitelist to gain full access to the site and its features.'
      ),
      btn: whiteListBtn,
      placement: 'bottomright',
      duration: 0
    }
    notification.open(args)
  }, [t])

  useEffect(() => {
    // no longer need
    const adblockerFF = false
    if (adblockerFF && showAdBlockNotification) {
      openAdBlockNotification()
    }
  }, [showAdBlockNotification, openAdBlockNotification])

  let animatedSidebarWidth
  if (mobileSidebarEnabled) {
    animatedSidebarWidth = sidebarWidth
  } else {
    animatedSidebarWidth = sidebarCollapsed
      ? collapsedSidebarWidth
      : sidebarWidth
  }
  let sidebarTransform
  if (mobileSidebarEnabled) {
    sidebarTransform = sidebarCollapsed
      ? `translateX(${-sidebarWidth}px)`
      : `translateX(0px)`
  } else {
    sidebarTransform = `translateX(0px)`
  }

  const sidebarStyle = useSpring({
    to: {
      width: animatedSidebarWidth,
      transform: sidebarTransform
    },
    config: sidebarAnimationConfig
  })

  const animatedContentMargin =
    mobileSidebarEnabled || !businessId ? 0 : animatedSidebarWidth

  const contentWrapperStyle = useSpring({
    to: { marginLeft: animatedContentMargin },
    config: sidebarAnimationConfig
  })

  const getRequest = useMemo(makeGetRequest, [])
  const { state: fetchBusinessesRequest } = useSelector((state) =>
    getRequest(state, 'business/fetchBusinesses')
  )

  useEffect(() => {
    if (!businessesDidLoad && fetchBusinessesRequest === 'resolved') {
      setBusinessesDidLoad(true)
    }
  }, [businessesDidLoad, fetchBusinessesRequest])

  useEffect(() => {
    if (businessesDidLoad) {
      setTimeout(() => {
        setShowReviewModal(true)
      }, 1000)
    }
  }, [businessesDidLoad])

  useEffect(() => {
    const body = document.getElementsByTagName('body')[0]
    if (mobileSidebarEnabled && !sidebarCollapsed) {
      // Prevent body from scrolling while the mobile sidebar is open
      body.setAttribute('style', 'overflow: hidden')
    }
    if (sidebarCollapsed || !mobileSidebarEnabled) {
      body.style.removeProperty('overflow')
    }
  }, [mobileSidebarEnabled, sidebarCollapsed])

  useEffect(() => {
    dispatch(setIsSidebarCollapsed(sidebarCollapsed))
  }, [dispatch, sidebarCollapsed])

  function renderPageContent() {
    if (!businessesDidLoad) {
      return <LoadingIndicator />
    }

    return children
  }

  if (fetchBusinessesRequest === 'rejected') {
    return (
      <Flex
        width="100%"
        height="100vh"
        justifyContent="center"
        alignItems="center"
      >
        <Result
          status="500"
          title={t('Hmmmm, something went wrong')}
          subTitle={t('Please contact support for more help')}
        />
      </Flex>
    )
  }

  return (
    <>
      {businessId && !isFullPage && (
        <Sidebar
          style={sidebarStyle}
          businessId={businessId}
          sidebarCollapsed={sidebarCollapsed}
          mobileSidebarEnabled={mobileSidebarEnabled}
          onToggleSidebar={() => {
            setSidebarCollapsed(!sidebarCollapsed)
          }}
          onClickLink={() => {
            if (mobileSidebarEnabled && !sidebarCollapsed) {
              setSidebarCollapsed(true)
            }
          }}
          showUsage={() => setShowUsage(!showUsage)}
        />
      )}
      {!isFullPage ? (
        <animated.div style={contentWrapperStyle}>
          <DismissibleBanner />
          <Navbar
            toggleHidden={!businessId}
            onToggleSidebar={() => {
              setSidebarCollapsed(!sidebarCollapsed)
            }}
          />
          <PageContainer>{renderPageContent()}</PageContainer>
        </animated.div>
      ) : (
        <PageContainer>{renderPageContent()}</PageContainer>
      )}
      {mobileSidebarEnabled && !isFullPage && (
        <SidebarMask
          opacity={sidebarCollapsed ? 0 : 1}
          height={sidebarCollapsed ? 0 : 100}
          onClick={() => {
            setSidebarCollapsed(true)
          }}
        />
      )}
      {showUsage && (
        <AccountUsage businessId={businessId} setShowUsage={setShowUsage} />
      )}
      {showReviewModal && (
        <ReviewModal onClose={() => setShowReviewModal(false)} />
      )}
      <ChangelogModal />
      <DevTools />
    </>
  )
}

AppLayout.propTypes = {
  children: PropTypes.node
}
